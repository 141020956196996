import { ActionTree } from 'vuex'
import axios, { AxiosResponse } from 'axios'
import { state } from '@/store/modules/course/Course'
import Api from '@/api/Api'
import { CourseState } from '@/store/modules/course/Course'
import { CourseInterface } from '@/models/course/Course.interface'
import { CourseMutations } from '@/store/modules/course/Course.mutations'
import { CourseEntryInterface } from '@/models/course-entries/CourseEntry.interface'
import { CourseCompletedFilterInterface } from '@/models/course/CourseCompletedFilter.interface'
import { CoursePlannedFilterInterface } from '@/models/course/CoursePlannedFilter.interface'
import { CourseTypes } from '@/models/course/Course.types'
import { CourseArchivedFilterInterface } from '@/models/course/CourseArchivedFilter.interface'
import { StoreModuleNames } from '@/store/ModuleNames';
import { GeneralActions } from '@/store/modules/general/General.actions';

import { collect } from 'collect.js'
import { formatCostsToNumber } from '@/functions'
import { apiFormatOrderFilter } from '@/api/src/Api'


export enum CourseActions {
    GET_STARTDATE_NOTIFICATIONS = 'GET_STARTDATE_NOTIFICATIONS',
    GET_COMPLETED = 'GET_COMPLETED',
    GET_ELEARNINGS = 'GET_ELEARNINGS',
    GET_ARCHIVED = 'GET_ARCHIVED',
    SORT_ARCHIVED = 'SORT_ARCHIVED',
    SORT_COMPLETED = 'SORT_COMPLETED',
    GET_PLANNED = 'GET_PLANNED',
    SORT_PLANNED = 'SORT_PLANNED',

    GET_COURSE = 'GET_COURSE',
    SET_PRICES = 'SET_PRICES',
    UPDATE_COURSE = 'UPDATE_COURSE',
    CREATE_COURSE = 'CREATE_COURSE',
    DELETE_COURSE = 'DELETE_COURSE',
    SET_COURSE_SCHEMA_FILES = 'SET_COURSE_SCHEMA_FILES',

    SEND_STARTDATE_MAILINGS = 'SEND_STARTDATE_MAILINGS'
}

export function usePrepareCourseModelForUpdate(context){
    const model = JSON.parse(JSON.stringify(context.state.courseSchema.model))
    const additionalCosts: any[] = collect(context.state.prices).flatten(1).filter(price => {
        return price['@type'] === 'Cost' && price['name'] !== ''
    }).all()

    model.additionalCosts = formatCostsToNumber(additionalCosts)
    model.prices = formatCostsToNumber(model.prices)
    model.ects = +model.ects

    return {
        model,
        additionalCosts
    }
}

// actions
export const actions: ActionTree<CourseState, any> = {
    /**
     * Get course
     * @param {Commit} commit
     * @param {Dispatch} dispatch
     * @param {any} id
     * @returns {Promise<void>}
     */
    [CourseActions.GET_COURSE]: async ({commit, dispatch}, {
        id
    }) => {
        try {
            commit(CourseMutations.RESET_STATE)

            const response: AxiosResponse<CourseInterface> = await Api.courses.showById(id)
            commit(CourseMutations.SET_COURSE, response.data)

            await dispatch(CourseActions.SET_PRICES)
            // await dispatch(CourseActions.GET_STARTDATE_NOTIFICATIONS)
        } catch(e){
            console.log(e)

            dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    [CourseActions.SET_PRICES]: async ({commit, getters}) => {
        commit(CourseMutations.SET_PRICES, getters.getPrices)
    },

    /**
     * Get startdate notifications
     * @param {Commit} commit
     * @param {any} id
     * @returns {Promise<void>}
     */
    [CourseActions.GET_STARTDATE_NOTIFICATIONS]: async ({commit, dispatch}) => {
        try {
            const response: AxiosResponse<CourseInterface> = await Api.courses.getStartDateNotifications(state.course)
            commit(CourseMutations.SET_STARTDATE_NOTIFICATIONS, response.data['hydra:member'])
        } catch(e){
            console.log(e)

            dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    /**
     * Get completed courses
     * @param {Commit} commit
     * @param {any} id
     * @returns {Promise<void>}
     */
    [CourseActions.GET_COMPLETED]: async ({
        commit,
        dispatch
    }, {
        courseId
    }) => {
        try {
            const response: AxiosResponse<CourseEntryInterface> = await Api.courseEntries.index({
                params: {
                    isCompleted: true,
                    gradesCompleted: false,
                    'course.id': courseId,
                    ...apiFormatOrderFilter(state.completedFilter)
                }
            })
            commit(CourseMutations.SET_COMPLETED, response.data['hydra:member'])
        } catch(e){
            console.log(e)

            dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    /**
     * Sort completed courses
     * @param {Commit} commit
     * @param {any} $event
     * @param {any} key
     * @returns {Promise<void>}
     */
    [CourseActions.SORT_COMPLETED]: async ({
          commit
      }, {
        $event,
        key
      }) => {
        const filters: CourseCompletedFilterInterface = {
            order: $event,
            order_by: key
        }

        commit(CourseMutations.UPDATE_COMPLETED_FILTER, filters)
    },

    /**
     * Get planned courses
     * @param {Commit} commit
     * @param {any} id
     * @returns {Promise<void>}
     */
    [CourseActions.GET_PLANNED]: async ({
      commit,
      rootGetters,
        dispatch
    }, {
        courseId
    }) => {
        try {
            const response: AxiosResponse<CourseEntryInterface> = await Api.courseEntries.index({
                params: {
                    'course.id': courseId,
                    isCompleted: false,
                    gradesCompleted: false,
                    type: [CourseTypes.classical, CourseTypes.webinar],
                    ...apiFormatOrderFilter(state.plannedFilter)
                }
            })

            await commit(CourseMutations.SET_PLANNED, response.data['hydra:member'])
            await commit(CourseMutations.SET_COURSE_START_DATE_OPTIONS, rootGetters['Course/getStartDateMailingOptions'])
        } catch(e){
            console.log(e)

            dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    /**
     * Sort planned courses
     * @param {Commit} commit
     * @param {any} $event
     * @param {any} key
     * @returns {Promise<void>}
     */
    [CourseActions.SORT_PLANNED]: async ({
       commit
    }, {
       $event,
       key
    }) => {
        const filters: CoursePlannedFilterInterface = {
            order: $event,
            order_by: key
        }

        commit(CourseMutations.UPDATE_PLANNED_FILTER, filters)
    },

    /**
     * Get elearnings
     * @param {Commit} commit
     * @param {any} courseId
     * @returns {Promise<void>}
     */
    [CourseActions.GET_ELEARNINGS]: async ({commit, dispatch}, {courseId}) => {
        try {
            const response: AxiosResponse<CourseEntryInterface> = await Api.courseEntries.index({
                params: {
                    'course.id': courseId,
                    'deletedAt[null]': true,
                    'archivedAt[null]': true,
                    isCompleted: false,
                    gradesCompleted: false,
                    type: [CourseTypes.elearning]
                }
            })

            commit(CourseMutations.SET_ELEARNINGS, response.data['hydra:member'])
        } catch(e){
            console.log(e)

            dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    /**
     * Get archived
     * @param {Commit} commit
     * @param {any} courseId
     * @returns {Promise<void>}
     */
    [CourseActions.GET_ARCHIVED]: async ({commit, dispatch}, {courseId}) => {
        try {
            const response: AxiosResponse<CourseEntryInterface> = await Api.courseEntries.index({
                params: {
                    'course.id': courseId,
                    isCompleted: true,
                    gradesCompleted: true,
                    ...apiFormatOrderFilter(state.archivedFilter)
                }
            })
            commit(CourseMutations.SET_ARCHIVED, response.data['hydra:member'])
        } catch(e){
            console.log(e)

            dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    /**
     * Sort archived
     * @param {any} commit
     * @param {any} $event
     * @param {any} key
     * @returns {Promise<void>}
     */
    [CourseActions.SORT_ARCHIVED]: async ({
        commit
    }, {
        $event,
        key
    }) => {
        const filters: CourseArchivedFilterInterface = {
            order: $event,
            order_by: key
        }

        commit(CourseMutations.UPDATE_ARCHIVED_FILTER, filters)
    },

    /**
     * Create course
     * @param {ActionContext<CourseState, any>} context
     * @returns {Promise<void>}
     */
    [CourseActions.CREATE_COURSE]: async (context) => {
        try {
            const {model} = usePrepareCourseModelForUpdate(context)
            const courseModel = JSON.parse(JSON.stringify(model))

            delete courseModel.prices

            const response: AxiosResponse<CourseInterface> = await Api.courses.create(courseModel)
            await Api.courses.update(response.data.id, model)
            const courseResponse: AxiosResponse<CourseInterface> = await Api.courses.showById(response.data.id)
            await context.commit(CourseMutations.SET_COURSE, courseResponse.data)
        } catch(e){
            await context.dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    /**
     * Updated course
     * @param {ActionContext<CourseState, any>} context
     * @returns {Promise<void>}
     */
    [CourseActions.UPDATE_COURSE]: async (context) => {
        try {
            const {model, additionalCosts} = usePrepareCourseModelForUpdate(context)
            const deleteApiCalls = []

            const deletedCosts = await additionalCosts.filter(costs => {
                return costs.deletedAt
            })

            deletedCosts.forEach(costs => {
                deleteApiCalls.push(Api.costs.update(costs.id, costs))
            })

            console.log(model)

            // Delete costs
            await axios.all(deleteApiCalls)
            await Api.courses.update(model.id, model)
            const courseResponse: AxiosResponse<CourseInterface> = await Api.courses.showById(model.id)

            await context.commit(CourseMutations.SET_COURSE, courseResponse.data)
        } catch(e){
            console.log(e)

            context.dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    /**
     * Delete course
     * @param {ActionContext<CourseState, any>} context
     * @returns {Promise<void>}
     */
    [CourseActions.DELETE_COURSE]: async (context) => {
        try {
            await Api.courses.delete(context.state.course.id)
        } catch(e){
            console.log(e)

            context.dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    },

    /**
     * Create course
     * @param {ActionContext<CourseState, any>} context
     * @returns {Promise<void>}
     */
    [CourseActions.SET_COURSE_SCHEMA_FILES]: async (context, {
        files
    }) => {
        context.commit(CourseMutations.SET_COURSE_SCHEMA_FILES, files)
        // try {
        //     // const {model} = usePrepareCourseModelForUpdate(context)
        //     // const courseModel = JSON.parse(JSON.stringify(model))
        //     //
        //     // delete courseModel.prices
        //     //
        //     // const response: AxiosResponse<CourseInterface> = await Api.courses.create(courseModel)
        //     // await Api.courses.update(response.data.id, model)
        //     // const courseResponse: AxiosResponse<CourseInterface> = await Api.courses.showById(response.data.id)
        //     //
        //     // context.commit(CourseMutations.SET_COURSE_FILES, courseResponse.data)
        // } catch(e){
        //     // console.log(e)
        //     //
        //     // context.dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
        //     //     root: true
        //     // })
        // }
    },

    /**
     * Send startdate notifications
     * @param {ActionContext<CourseState, any>} context
     * @returns {Promise<void>}
     */
    [CourseActions.SEND_STARTDATE_MAILINGS]: async (context, {
        entries
    }) => {
        try {
            await Api.courses.sendStartDateNotifications(context.state.course, entries)
        } catch(e){
            console.log(e)

            context.dispatch(`${StoreModuleNames.General}/${GeneralActions.REQUEST_FAILED_GLOBAL_MESSAGE}`, {}, {
                root: true
            })
        }
    }
}
