import Api from '@/api/src/Api'
import { AxiosPromise } from 'axios'
import { CourseInterface } from '@/models/course/Course.interface'
import merge from 'deepmerge';

export default class CoursesApi extends Api {
    constructor() {
        super()

        this.endPoint = 'courses'
    }

    update(id: string, data: Partial<CourseInterface> = {}): AxiosPromise {
        data.files = data.files.map(file => file['@id']);

        return this.axios.put(`${this.endPoint}/${id}`, data)
    }

    /**
     * Get startdate notifications
     * @param {CourseInterface} course
     * @param {Record<string, any>} options
     * @returns {AxiosPromise}
     */
    getStartDateNotifications(course: CourseInterface, options: Record<string, any> = {}): AxiosPromise {
        options = merge({
            params:{
                'order[date]': 'DESC',
                'itemsPerPage': 999
            }
        }, options)

        return this.axios.get(`${this.endPoint}/${course.id}/start_date_notifications`, options)
    }

    /**
     * Send startdate notifications
     * @param {CourseInterface} course
     * @param {string[]} data
     * @returns {Promise<AxiosResponse<any>>}
     */
    sendStartDateNotifications(course: CourseInterface, data: string[]): AxiosPromise{
        return this.axios.post(`${this.endPoint}/${course.id}/send-start-mailing`, data)
    }

    /**
     * Get course entries
     * @param {CourseInterface} course
     * @param {Record<string, any>} options
     * @returns {AxiosPromise}
     */
    getCourseEntries(course: CourseInterface, options: Record<string, any> = {}): AxiosPromise {
        return this.axios.get(`${this.endPoint}/${course.id}/course_entries`, options)
    }
}
